export const environment = {
  production: false,
  title: 'dev',
  version: 'v1.0',
  apiBaseUrl: 'https://minasidor-dev.mockfjards.se/api',
  auth0LogoutUrl: 'https://minasidor-dev.mockfjards.se/', // redirect after successful logout
  auth0Callback: 'https://minasidor-dev.mockfjards.se/overview', // redirect after successful login
  auth0Domain: 'dev-rdj3pxvy.eu.auth0.com',
  auth0ClientId: 'RgdfE2JmlO1bvKH2I8n8EcMjLfsCwqW1',
  auth0Audience: 'mockfjards-mypages-api-dev',
  airbrake: { id: 593212, key: '2fcbc2a7b10600cd8f75decfe03bc202' }
};
