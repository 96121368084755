import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { ErrorService } from '@services/error.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

  #errorService = inject(ErrorService)

  private readonly knownStatusTitles = new Set([400, 401, 404, 500, 502])

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        const errorTitle = this.getErrorTitle(error)

        this.#errorService.notifyError(error, {title: errorTitle, message: `Kontakta kundtjänst om felet kvarstår (${error?.name}).`})

        return EMPTY; // returning EMPTY prevents the error to propagate further (avoiding airbrake to catch the error and log it)
      })
    );
  }

  private getErrorTitle(error: HttpErrorResponse): string {
    // Client-side error
    if (error?.error instanceof ErrorEvent) return 'Ett fel uppstod'

    // Server-side error
    return this.knownStatusTitles.has(error.status) ? `Error - ${error.status}` : 'Okänt fel'
  }
}
